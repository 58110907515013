import type { FC, ReactNode } from "preact/compat";
import classnames from "classnames";

export const TitleText: FC<{
  text: string;
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  className?: string;
  align?: "left" | "center";
  padding?: "sm" | "md" | "lg";
  paddingBottom?: "sm" | "md" | "lg";
  paddingTop?: "sm" | "md" | "lg";
  paddingX?: "sm" | "md" | "lg";
  paddingY?: "sm" | "md" | "lg";
  size?: "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl";
  children?: ReactNode;
}> = ({
  text,
  variant,
  size,
  className,
  align = "left",
  padding,
  paddingBottom,
  paddingTop,
  paddingX,
  paddingY,
  children
}) => {
  const Component = variant || "h3";
  const sizeClass = classnames({
    "text-lg": size === "lg",
    "text-xl": size === "xl",
    "text-2xl": size === "2xl",
    "text-3xl": size === "3xl",
    "text-4xl": size === "4xl",
    "text-5xl": size === "5xl",
    "text-6xl": size === "6xl",
  });
  const alignClass = classnames({
    "text-left": align === "left",
    "text-center": align === "center",
  });
  return (
    <Component
      class={classnames(
        "text-2xl font-medium",
        className,
        sizeClass,
        alignClass,
      )}
    >
      {text}
      {children}
    </Component>
  );
};

export const BodyText: FC<{
  text: string;
  variant: "span" | "p";
  className?: string;
  align?: "left" | "center";
  size?: "xs" | "sm" | "md" | "lg";
  children?: ReactNode;
  padding?: "sm" | "md" | "lg";
  paddingBottom?: "sm" | "md" | "lg";
  paddingTop?: "sm" | "md" | "lg";
  paddingX?: "sm" | "md" | "lg";
  paddingY?: "sm" | "md" | "lg";
}> = ({ text, variant, size, className, align = "left", children }) => {
  const Component = variant || "p";
  const sizeClass = classnames({
    "text-xs": size === "xs",
    "text-sm": size === "sm",
    "text-base": size === "md",
    "text-lg": size === "lg",
  });
  const alignClass = classnames({
    "text-left": align === "left",
    "text-center": align === "center",
  });
  return (
    <>
      <Component
        class={classnames(
          "text-2xl font-medium",
          className,
          sizeClass,
          alignClass,
        )}
      >
        {text}
        {children}
      </Component>
    </>
  );
};
