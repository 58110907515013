import {
  useState,
  type FC,
  type ReactNode,
  useRef,
  useEffect,
} from "preact/compat";
import Icons from "@components/icons.ts";

export const CardWithAccordionItem: FC<{
  heading: string;
  iconName: string;
  children: ReactNode;
  isOpen?: boolean;
}> = ({ heading, iconName, children, isOpen = false }) => {
  const [active, setActive] = useState(isOpen);
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      let target = e.target as Element;
      while (target && !target.id.includes("accordion-button")) {
        target = target.parentElement as Element;
      }
      if (target && target.id !== `accordion-button-${heading}`) {
        setActive(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [heading]);

  useEffect(() => {
    if (active && ref.current) {
      const height = ref.current?.scrollHeight;
      return setHeight(height ?? 0);
    }
    setHeight(0);
  }, [active, ref, children]);

  return (
    <div className="w-full px-0 px-4 py-4 border-solid border-[1px] rounded-md border-gt-black">
      <button
        id={`accordion-button-${heading}`}
        className="flex w-full justify-between items-center cursor-pointer"
        onClick={() => setActive(!active)}
      >
        <span class="flex items-center justify-center w-12 h-10 rounded-full bg-gt-lilac-light">
          <img
            src={`/wp/icons/${iconName}-icon.svg`}
            alt={`${iconName} icon`}
            loading="lazy"
          />
        </span>
        <h3 className="w-full text-left text-base font-medium text-l md:text-xl pl-4">
          {heading}
        </h3>
        <span
          class={
            "align-self-end border border-solid border-gt-slate-light rounded p-2"
          }
        >
          <Icons.PlusRotatingIcon shouldRotate={active} />
        </span>
      </button>
      <div
        style={{ height }}
        ref={ref}
        data-state={active ? "active" : "inactive"}
        className="Accordion__content overflow-hidden text-left text-base normal-case px-6 pl-14 pr-12"
      >
        {children}
      </div>
      <style jsx>{`
        .Accordion__content {
          opacity: 0;
          transition: height 300ms linear;
        }

        .Accordion__content[data-state="active"] {
          width: 100%;
          opacity: 1;
        }
      `}</style>
    </div>
  );
};
