import classnames from "classnames";
import { useEffect, useState, type FC } from "preact/compat";
import { useIsMobile } from "@hooks/useIsMobile";

export type LinksRendererProps = {
  divider?: {
    position: "top" | "bottom";
    class?: string;
  };
  container?: {
    class?: string;
  };
  link_item: {
    underline?: boolean;
    class?: string;
  };
  links_wrapper?: {
    class?: string;
  };
  links: Array<{ link: { title: string; url: string } }>;

  heading?: {
    text?: string;
    variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    class?: string;
  };
  columns: "1" | "2" | "3" | "4" | "5" | "6";
};
export const LinksRenderer: FC<LinksRendererProps> = ({
  container,
  links,
  link_item,
  links_wrapper,
  heading,
  columns,
  divider,
}) => {
  const isMobile = useIsMobile();
  const [items, setItems] = useState<
    Array<{ link: { title: string; url: string } }>
  >([]);
  useEffect(() => {
    if (isMobile) {
      setItems(links.slice(0, 7));
    } else {
      setItems(links);
    }
  }, [isMobile]);
  const handleShowMoreClick = () => {
    setItems(links);
  };
  const mapToDivider = {
    top: "border-t border-t-solid border-t-gt-black",
    bottom: "border-b border-b-solid border-b-gt-black",
  };
  const HeaderComponent = heading?.variant || "h5";
  const headerClass = heading?.class || "font-bold py-4 md:py-8";
  return (
    <div
      class={classnames(
        "flex flex-col w-full my-4",
        container?.class,
        mapToDivider[divider?.position || "top"],
        divider?.class,
      )}
    >
      {heading && HeaderComponent && (
        <HeaderComponent class={classnames(headerClass)}>
          {heading?.text}
        </HeaderComponent>
      )}

      <div class="relative w-full">
        <ul
          class={classnames(
            "flex flex-wrap list-none justify-start items-start w-full",
            links_wrapper?.class,
          )}
        >
          {items.map((_link) => (
            <li
              class={classnames("p-0 m-0 basis-full", {
                ["lg:basis-full"]: columns === "1",
                ["lg:basis-1/2"]: columns === "2",
                ["lg:basis-1/3"]: columns === "3",
                ["lg:basis-1/4"]: columns === "4",
                ["lg:basis-1/5"]: columns === "5",
                ["lg:basis-1/6"]: columns === "6",
                ["underline"]: link_item?.underline,
                ["no-underline"]: !link_item?.underline,
              })}
            >
              <a href={_link.link.url}>{_link.link.title}</a>
            </li>
          ))}
        </ul>
        {(links.length > 6 && isMobile && items.length < links.length) && (
          <span class="Link__transparent--gradient" />
        )}
      </div>
      {links.length > 6 && isMobile && <button onClick={handleShowMoreClick}>Show more</button>}
    </div>
  );
};
