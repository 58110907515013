import type { FC } from "preact/compat";
import classnames from "classnames";

import type { IconValues, Media } from "@services/wordpress/wordpress.types";
import Icon from "@components/Icons/Icon/index.astro";

export const SectionHeader: FC<{
  container?: {
    class?: string;
  };
  heading: {
    text: string;
    variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    class?: string;
  };
  copy?: {
    text: string;
    class?: string;
  };
  badge?: {
    text: string;
    class?: string;
  };
  icon?: {
    value: IconValues;
    class?: string;
  };
  image?: {
    media: Media;
    height?: number;
    width?: number;
  };
}> = ({ heading, copy, badge, image, icon, container }) => {
  const HeaderComponent = heading.variant;
  return (
    <div
      class={classnames(
        {
          "flex flex-col items-center lg:max-w-2xl": !container?.class,
        },
        container?.class,
      )}
    >
      {badge?.text && (
        <div
          class={classnames(
            {
              "w-fit my-4 px-3 py-[2px] rounded-full text-sm bg-gt-lilac-light":
                !badge?.class,
            },
            badge?.class,
          )}
        >
          {badge.text}
        </div>
      )}
      {icon && icon.value !== "none" && (
        <div
          class={classnames(
            {
              "flex items-center justify-center w-16 h-16 rounded-full bg-gt-lilac-light mb-4":
                !icon?.class,
            },
            icon?.class,
          )}
        >
          <Icon iconName={icon.value} />
        </div>
      )}
      {image && (
        <img
          class="my-8"
          height={
            image?.height ? image.height : image.media.media_details.height
          }
          width={image?.width ? image.width : image.media.media_details.width}
          alt={image.media?.alt_text}
          src={image.media?.source_url}
        />
      )}

      <HeaderComponent
        class={classnames(
          {
            "text-4xl text-center": !heading?.class,
          },
          heading.class,
        )}
      >
        {heading.text}
      </HeaderComponent>

      {copy?.text && (
        <p
          class={classnames(
            {
              "px-3 md:py-4 text-center": !copy.class,
            },
            copy.class,
          )}
        >
          {copy.text}
        </p>
      )}
    </div>
  );
};
